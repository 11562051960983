
import {Component, Vue} from "vue-property-decorator";
import {
  deliveryPeriodCreateApi,
  deliveryPeriodModifyApi,
  deliveryPeriodQueryApi,
  deliveryPeriodRemoveApi,
  dictDetailApi,
  dictSetUpApi
} from "@/apis/sale/settings";
import {IDelivery} from "@/apis/sale/settings/types";
import {ref, Ref} from "vue";
import {IDict} from "@/apis/decorate/types";

/**
 * @name: 销售管理-销售设置
 * @author: lili
 * @date: 2023-07-20 14:08
 * @description： 销售管理-销售设置
 * @update: 2023-07-20 14:08
 */
@Component({})
export default class RolePage extends Vue {

  activeName: string = 'first'
  query: any = {}
  // 新增/编辑 表单
  form: Partial<IDelivery> = {}
  // loading
  loading: boolean = false
  isAdd: boolean = false
  // 列表
  data: IDelivery[] = []

  formData = {
    deliveryDuration: null,
    deliveryPrice: null,
    deliveryFee: null
  }

  /**
   * 查询列表
   */
  getList() {
    this.loading = true;
    deliveryPeriodQueryApi().then(e => {
      this.data = e.map((e: any) => {
        e.isEdit = false;
        e.timeStr = [e.startTime, e.endTime]
        return e
      });
      this.loading = false;
    })
  }


  handleClick() {
  }

  /**
   * 弹窗修改或新增
   * @param row
   */
  handleAddAndUpdate(type: number, row: any, index?: number) {
    if (this.isAdd) {
      this.$message({
        type: 'error',
        message: '不能同时编辑多个!'
      });
      return
    }
    this.isAdd = true;
    this.data.map((e: any) => e.isEdit = false);
    if (type === 0) {
      this.data.push({
        isEdit: false,
        timeStr: ['00:00','23:00'],
      });
      this.data[this.data.length - 1].isEdit = true;
    } else {
      row.isEdit = true;
      row.timeStr = [row.startTime, row.endTime];
      console.log(index, 'index')
    }
  }

  /**
   * 修改或新增
   * @param row
   */
  inputSubmit(row: any) {
    row.startTime = row.timeStr[0];
    row.endTime = row.timeStr[1];
    let formRef: Ref<IDelivery> = ref({});
    formRef.value.startTime = row.startTime;
    formRef.value.endTime = row.endTime;
    if (row.id) {
      formRef.value.id = row.id;
      deliveryPeriodModifyApi(formRef.value).then(e => {
        this.$message({
          type: 'success',
          message: '操作成功!'
        });
        this.isAdd = false;
        this.getList()
      })
    } else {
      deliveryPeriodCreateApi(formRef.value).then(e => {
        this.$message({
          type: 'success',
          message: '操作成功!'
        });
        this.isAdd = false;
        this.getList()
      })
    }
  }

  /**
   * 取消
   * @param row
   */
  cloneSubmit(row: any) {
    row.isEdit = false;
    this.isAdd = false;
    this.getList()
  }

  /**
   * 删除
   * @param row
   */
  handleDelete(row: any) {
    if (!row["id"] || row.id == '') {
      this.isAdd = false;
      this.getList()
      return
    }
    this.$confirm("是否确认删除？", {
      confirmButtonText: "是",
      cancelButtonText: "否",
      center: true
    }).then(() => {

      deliveryPeriodRemoveApi(row["id"] || '').then(e => {
        this.$message({
          type: 'success',
          message: '操作成功!'
        });
        this.getList()
      })
    });
  }

  /**
   * 获取字典
   * @param row
   */
  getDictDetail() {
    // 1-小程序首页背景图 2-预计配送时长 3-配送时间 4-起送价 5-配送费
    dictDetailApi(2).then(e => {
      this.formData.deliveryDuration = e.value;
    })
    dictDetailApi(4).then(e => {
      this.formData.deliveryPrice = e.value;
    })
    dictDetailApi(5).then(e => {
      this.formData.deliveryFee = e.value;
    })
  }

  /**
   * 配置字典
   * @param row
   */
  submitDict(types: number) {
    // 1-小程序首页背景图 2-预计配送时长 3-配送时间 4-起送价 5-配送费
    let values = types == 2 ? this.formData.deliveryDuration : types == 4 ? this.formData.deliveryPrice : this.formData.deliveryFee as any
    if(values==''||values===null){
      this.getDictDetail()
      return
    }
    let obj: IDict = {
      types,
      values
    }
    dictSetUpApi(obj).then(e => {
      this.getDictDetail()
    })
  }

  mounted() {
    this.getDictDetail();
    this.getList()
  }
}
