import {get, postJ} from "@/request"
import {IPageRes} from "@/apis/page";
import {IDelivery, IUserQuery} from "@/apis/sale/settings/types";
import {IDict} from "@/apis/decorate/types";

/**
 * 分页查询
 * @param query 查询参数
 * @returns
 */
export const deliveryPeriodQueryApi = () => get<IDelivery[]>("/mall/deliveryPeriod/query")
/**
 * 创建
 * @param data 参数
 * @returns
 */
export const deliveryPeriodCreateApi = (data: IDelivery) =>  postJ("/mall/deliveryPeriod/create", data)
/**
 * 修改
 * @param data 参数
 * @returns
 */
export const deliveryPeriodModifyApi = (data: IDelivery) =>  postJ("/mall/deliveryPeriod/modify", data)
/**
 * 删除
 * @param id id
 * @returns
 */
export const deliveryPeriodRemoveApi = (id: string) => get("/mall/deliveryPeriod/remove/" + id)

/**
 * 预计配送时长,起送价/配送费-创建
 * @param param
 */
export const dictSetUpApi = (param: IDict) => get("/mall/dict/setUp", param)

/**
 * 预计配送时长,起送价/配送费-详情
 * @param param
 */
export const dictDetailApi = (types:number) => get("/mall/dict/detail", {types})

/**
 * 用户表-分页查询
 * @param query 查询参数
 * @returns
 */
export const userQueryApi = (param:IUserQuery) => get("/mall/user/query",param)

/**
 * 客户信息导出Excel
 * @param param
 */
export const userExportApi = (param: any) => postJ("/mall/user/exportUser",  param, 'blob')
